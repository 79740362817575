import React from 'react';
import GuaShaHero from '../guaShaComponenets/GuaShaHero';
import GuaShaInfluencerShowcase from '../guaShaComponenets/GuaShaInfluencerShowcase';
import FeaturesSection from '../components/FeaturesSection';
import { FaLeaf, FaHeartbeat, FaEye, FaSmile, FaTools, FaHands } from 'react-icons/fa';
import FinalMessageCTASection from '../components/FinalMessageCTASection';
import Logo from '../images/logo.png';
import LuxuriousPopup from '../components/LuxuriousPopup';

function GuaSha() {
  const features = [
    {
      title: 'បន្តឹងស្បែកដោយធម្មជាតិ',
      description: 'ទទួលបានបទពិសោធន៍នៃការបន្តឹងស្បែកមុខដោយធម្មជាតិជាមួយនឹងឧបករណ៍ Gua Sha របស់យើង ដែលផលិតពីដែកអ៊ីណុក (stainless steel) ដើម្បីផ្តល់នូវលទ្ធផលល្អបំផុត',
      icon: <FaLeaf size={30} color='#32A605' />
    },
    {
      title: 'ជំរុញចរន្តឈាម',
      description: 'ពង្រឹង​ចរន្ត​ឈាម​ស្បែក​ឱ្យ​ភ្លឺ​ថ្លា និង​មាន​សុខភាព​ល្អ​',
      icon: <FaHeartbeat size={30} color='#E53E3E' />
    },
    {
      title: 'កាត់បន្ថយការហើម',
      description: 'កាត់បន្ថយភាពហើម និងដក់ក្រោមភ្នែក (eye bags) យ៉ាងមានប្រសិទ្ធភាព ដើម្បីទទួលបានរូបរាងស្រស់ស្រាយ',
      icon: <FaEye size={30} color='#3182CE' />
    },
    {
      title: 'បន្ធូរភាពតានតឹង',
      description: 'បន្ធូរភាពតានតឹង និងភាពតានតឹងលើផ្ទៃមុខដោយប្រើឧបករណ៍ Gua Sha របស់យើងជាប្រចាំ',
      icon: <FaSmile size={30} color='#ECC94B' />
    },
    {
      title: 'គុណភាពដែក stainless steel',
      description: 'ផលិតពីដែកអ៊ីណុកគុណភាពខ្ពស់ ធានាបាននូវភាពធន់ និងរូបរាងស្អាត',
      icon: <FaTools size={30} color='#718096' />
    },
    {
      title: 'ងាយស្រួល​ប្រើ',
      description: 'ការរចនាដែលងាយស្រួលប្រើធ្វើឱ្យវាសាមញ្ញក្នុងការបញ្ចូលទៅក្នុងទម្លាប់ថែរក្សាស្បែកប្រចាំថ្ងៃរបស់អ្នក',
      icon: <FaHands size={30} color='#805AD5' />
    }
  ];

  
  return (
    <div className="min-h-screen text-black">
      <GuaShaHero/>
      <GuaShaInfluencerShowcase/>
      <FeaturesSection features={features}/>
      <FinalMessageCTASection
        logo={Logo}
        mainHeading="មុខតូច ស្អាតជាមួយ Gua Sha"
        subHeading="សម្រស់ ប្រណិត ធម្មជាតិ"
        buttonText="ទិញ​ឥឡូវ"
        buttonLink="https://www.instagram.com/botum.official/?hl=en"
      />
      <LuxuriousPopup/>
    </div>
  );
}

export default GuaSha;
