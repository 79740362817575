import React from 'react';
import PrizeList from '../components/Games/PrizeList';
import Leaderboard from '../components/Games/Leaderboard';
import CTA from '../components/Games/GameCTA';

const GameLeaderBoard = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4 pt-32 md:pt-0">
      <div className="w-full max-w-6xl flex flex-wrap md:flex-nowrap bg-white shadow-lg rounded-lg ">
        {/* Prize List on the Left */}
        <PrizeList />

        {/* Leaderboard on the Right */}
        <Leaderboard />
      </div>


    </div>
  );
};

export default GameLeaderBoard;
