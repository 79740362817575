import React from 'react';
import HeroImage from '../images/hero.png';
import Logo from '../images/logo.png';

import '../styles/HeroAnimations.css'; // Make sure your path is correct

function HeroSection() {

  const handleNav = () => {
    window.open('https://www.instagram.com/botum.official/?hl=en', '_blank'); // Replace with your desired link
  }

  return (
    <div className=" flex flex-wrap items-center h-screen bg-primary text-white  md:pt-0 md:justify-evenly">
      <div className="w-full md:w-1/2 xl:px-32 p-5 flex flex-col justify-center items-center text-center md:text-left  animate-opacity">

        <div className="space-y-4 animate-fadeSlideUp md:mt-0 mt-32 sm:mt-12 px-2 md:px-0">
          <h1 className="md:text-4xl lg:text-5xl font-bold mb-4 animate-textPop text-3xl nokora leading-normal">បំបាត់មុនក្នុងយៈពេល 7 ថ្ងៃ</h1>
          <p className="lg:text-3xl md:text-2xl text-lg text-gray-300 ">ស្លឹកត្រចៀកក្រាញ់ វលិ្លយាវក្នុង Botum Mask និង Spray គឺបំបាត់មុន រោល​ ក្រហម កន្ទួល និងស្នាម</p>
          <button onClick={handleNav} className="nokora bg-white text-primary font-bold text-lg md:text-xl py-2 px-4 rounded hover:bg-gray-300 transition duration-300 ease-in-out shadow-md hover:shadow-lg animate-btnPulse">
            ទិញ​ឥឡូវ
          </button>
          <p className="text-md md:text-lg font-bold nokora">ភ្ញៀវបានទិញ Botum 10,000+ ឈុត!</p>
        </div>
      </div>
      
      {/* Image Side */}
      <div className="w-full md:w-1/2 xl:w-1/3 flex justify-center items-center p-5 animate-scaleIn md:mt-0 ">
        <img src={HeroImage} alt="Botum Visual" className="w-full" />
      </div>
    </div>
  );
}

export default HeroSection;
