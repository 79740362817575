import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles and the styles for the modules you're going to use
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation'; // Navigation module styles
import 'swiper/css/pagination'; // Pagination module styles

import JNeath from '../images/influencers/jNeath.jpg';
import Boromey from '../images/influencers/boromey.jpg';
import Chechzy from '../images/influencers/chechzy.jpg';
import Lxing from '../images/influencers/lxing.jpg';
import Kalyanney from '../images/influencers/kalyanney.jpg';
import Lala from '../images/influencers/lala.jpg';
import LimAlice from '../images/influencers/limalice.jpg';

import '../styles/CustomSwiperStyles.css'; // Assuming this file contains your custom Swiper styles
 
const InfluencerShowcase = () => {
  const influencers = [
    { username: "@kalyanney", imageUrl: Kalyanney, accountUrl: "https://www.tiktok.com/@kalyanney/video/7341377151461084418?_r=1&_t=8kKPolrOMKX" },
    { username: "@the20slala", imageUrl: Lala, accountUrl: "https://www.tiktok.com/@jjneathh/video/7327672516493806849?_r=1&_t=8jN5htAigmW&social_sharing=1" },
    { username: "@jjneathh", imageUrl: JNeath, accountUrl: "https://www.tiktok.com/@jjneathh/video/7327672516493806849?_r=1&_t=8jN5htAigmW&social_sharing=1" },
    { username: "@seychanboromey", imageUrl: Boromey, accountUrl: "https://www.tiktok.com/@seychanboromey/video/7327992079601241352?_r=1&_t=8jN5dFIMrE0&social_sharing=1" },
    { username: "@chech.zy", imageUrl: Chechzy, accountUrl: "https://www.tiktok.com/@chech.zy/video/7327529867849764098?_r=1&_t=8jN5U07yZOS&social_sharing=1" },
    { username: "@lyxingbaebae", imageUrl: Lxing, accountUrl: "https://www.tiktok.com/@lyxingbaebae/video/7328323319164341506?_r=1&_t=8jN5ZP82D9L&social_sharing=1" },
    { username: "@lim__alice", imageUrl: LimAlice, accountUrl: "https://www.tiktok.com/@lim__alice/video/7360506414856473863?_r=1&_t=8mt4GuKzCEa"},

  ];

  return (
    <div id="influencers" className="py-10 px-4 md:px-10">
    <div className='w-full flex items-center justify-center'>
    <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-bold text-gray-600 mb-10 mt-4 relative inline-block">
        Influencer Support
        <span className="block w-full h-1 bg-primary mt-2"></span> {/* Underline Effect */}
    </h2>      
    </div>
  <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1} // Default to 1 view for the smallest screens
        breakpoints={{
          // When window width is >= 640px
            640: {
            slidesPerView: 2,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 3,
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
          },

        }}
        navigation    
        pagination={{ clickable: true }}
    
        scrollbar={{ draggable: true }}        
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {influencers.map((influencer, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center mb-2">
              <a href={influencer.accountUrl} target="_blank" rel="noopener noreferrer"  className=" hover:opacity-80 w-1/2 lg:w-3/4  transition-opacity duration-300 p-4 bg-white rounded-lg shadow-lg md:w-5/6 w-3/4">
                <img src={influencer.imageUrl} alt={`Botum with ${influencer.username}`} className="mx-auto w-full  object-cover rounded-md"/>
                <div className="text-center mt-4">
                  <p className="font-semibold text-lg text-primary hover:text-secondary tranistion">{influencer.username}</p>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default InfluencerShowcase;
