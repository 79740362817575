import React from 'react';

const FreeBotumBanner = ({ openModal }) => {
  return (
    <div
      className="bg-secondary text-white text-lg md:text-2xl px-4 py-2 cursor-pointer hover:bg-secondary-dark transition duration-300 w-full"
      onClick={openModal}
    >
      <p className="text-center font-semibold text-xl md:text-2xl">
        FREE BOTUM!<span className='text-gray-200 text-sm'> (ចុចខ្ញុំ)</span>
      </p>
    </div>
  );
};

export default FreeBotumBanner;
