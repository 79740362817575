import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles and the styles for the modules you're going to use
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation'; // Navigation module styles
import 'swiper/css/pagination'; // Pagination module styles

import Leaksmey from '../images/guaShaInfluencers/leaksmey.jpg';
import k_huongg from '../images/guaShaInfluencers/k_huongg.jpg';
import Kalyanney from '../images/guaShaInfluencers/kalyanneyGuaSha.jpg';
import AoiChann from '../images/guaShaInfluencers/aoichann.jpg';

import '../styles/CustomSwiperStyles.css'; // Assuming this file contains your custom Swiper styles
 
const GuaShaInfluencerShowcase = () => {
  const influencers = [
    { username: "@kalyanney", imageUrl: Kalyanney, accountUrl: "https://www.tiktok.com/@kalyanney/video/7373676646286576897?_r=1&_t=8musk8fy9JZ" },
    { username: "@aoichhann", imageUrl: AoiChann, accountUrl: "https://www.tiktok.com/@aoichhann/video/7369228344883154197?_r=1&_t=8muriLv7EEz" },
    { username: "@kuoyhuong", imageUrl: k_huongg, accountUrl: "https://www.tiktok.com/@kuoyhuong/video/7369081356900502792?_r=1&_t=8murj6m534b" },
    { username: "@leaksmey0925", imageUrl: Leaksmey, accountUrl: "https://www.tiktok.com/@leaksmey0925/video/7372037778252647688?_r=1&_t=8murd3H2tHd" },

  ];  

  return (
    <div id="influencers" className="py-10 px-4 md:px-10">
    <div className='w-full flex items-center justify-center'>
    <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-bold text-gray-600 mb-10 mt-4 relative inline-block">
        Influencer Support
        <span className="block w-full h-1 bg-primary mt-2"></span> {/* Underline Effect */}
    </h2>      
    </div>
  <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1} // Default to 1 view for the smallest screens
        breakpoints={{
          // When window width is >= 640px
            640: {
            slidesPerView: 2,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 3,
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
          },

        }}
        navigation    
        pagination={{ clickable: true }}
    
        scrollbar={{ draggable: true }}        
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {influencers.map((influencer, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center mb-2">
              <a href={influencer.accountUrl} target="_blank" rel="noopener noreferrer"  className=" hover:opacity-80 w-1/2 lg:w-3/4  transition-opacity duration-300 p-4 bg-white rounded-lg shadow-lg  md:w-5/6 w-3/4">
                <img src={influencer.imageUrl} alt={`Botum with ${influencer.username}`} className="mx-auto w-full  object-cover rounded-md"/>
                <div className="text-center mt-4">
                  <p className="font-semibold text-lg text-primary hover:text-secondary tranistion">{influencer.username}</p>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default GuaShaInfluencerShowcase;
