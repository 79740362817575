import React, { useEffect, useRef } from 'react';
import '../styles/FinalMessageCTASection.css';

function FinalMessageCTASection({ logo, mainHeading, subHeading, buttonText, buttonLink }) {
  const finalMessageRef = useRef(null);

  const handleNav = () => {
    window.open(buttonLink, '_blank');
  }

  useEffect(() => {
    const finalMessageSection = finalMessageRef.current;
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const triggerPoint = finalMessageSection.offsetTop - window.innerHeight / 3;

      if (scrollTop >= triggerPoint) {
        finalMessageSection.scrollIntoView({ behavior: 'smooth' });
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={finalMessageRef} id="final-message-cta" className="final-message-cta-section flex flex-col justify-center items-center bg-primary">
      <div className='mb-8 sm:w-1/2 md:w-full w-3/4 flex justify-center animate-fadeSlideUp'>
        <img src={logo} className='md:w-1/3' alt="Logo"/>
      </div>
      <h2 className="text-white lg:text-4xl text-3xl text-bold nokora mb-4">{mainHeading}</h2>
      <h2 className="text-white lg:text-3xl text-2xl text-bold mb-8 animate-pulse">{subHeading}</h2>
      <button onClick={handleNav} className="animate-bounce bg-white text-primary font-bold text-xl py-2 px-4 rounded hover:bg-gray-300 transition duration-300 ease-in-out shadow-md hover:shadow-lg">{buttonText}</button>
    </div>
  );
}

export default FinalMessageCTASection;
