import React, { useState, useEffect } from 'react';

function LuxuriousPopup() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Display the popup after 5 seconds
    const timer = setTimeout(() => {
      setVisible(true);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer if the component unmounts
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6 bg-white p-4 sm:p-6 rounded-lg shadow-xl border-2 border-gold-400 z-50 max-w-xs sm:max-w-sm w-full sm:w-auto">
      <div className="flex justify-between items-center">
        <h3 className="text-md md:text-lg font-bold text-gray-700">FREE ថ្នាក់ធ្វើឲមុខស្អាត</h3>
        <button onClick={handleClose} className="text-gray-400 hover:text-gray-600 text-2xl">&times;</button>
      </div>
      <p className="text-md md:text-lg text-gray-600 mt-2">
      យល់ដឹងពីស្បែកមុខ (skintype) ឲកាន់តែច្បាស់ជាមួយ course នេះ កុំឲរើសផលិតផលខុស និងស្បែកកាន់តែស្អាត! 🌟
      </p>
      <a
        href="https://www.instagram.com/botum.official/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-3 sm:mt-4 inline-block bg-yellow-400 text-white font-bold py-1 sm:py-2 px-3 sm:px-4 rounded-full hover:bg-gold-500 transition duration-300 ease-in-out text-xs sm:text-sm"
      >
        Join Now
      </a>
    </div>
  );
}

export default LuxuriousPopup;
