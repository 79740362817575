import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import Logo from '../images/logo.png';
import FinalMessageCTASection from "../components/FinalMessageCTASection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Star() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState('grid'); // State to manage view mode
  const [products, setProducts] = useState([]);
  const productsPerPage = 8;

  useEffect(() => {
    fetch('https://botum-6ea16f5d8e73.herokuapp.com/guide/reviews')
      .then(response => response.json())
      .then(data => {
        const productsWithIds = data.map((product, index) => ({
          ...product,
          id: index + 1
        }));
        setProducts(productsWithIds);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Logic for filtering products based on search term
  const filteredProducts = products.filter(product =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Logic for displaying products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Logic for pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen text-black">
      {/* Product Section */}
      <div className="container mx-auto p-4 mt-20 relative"> 
        <div className="flex justify-between mb-4 items-center">
          <div className="relative w-full max-w-xs">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border p-2 pl-10 rounded-md"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
            </div>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 border rounded-md ${viewMode === 'grid' ? 'bg-gray-300' : ''}`}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h7v7H3zM14 3h7v7h-7zM3 14h7v7H3zM14 14h7v7h-7z" /></svg>
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 border rounded-md ${viewMode === 'list' ? 'bg-gray-300' : ''}`}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
            </button>
          </div>
        </div>

        <div className={`grid ${viewMode === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6' : 'grid-cols-1 gap-2'}`}>
          {currentProducts.map(product => (
            <ProductCard key={product.id} product={product} viewMode={viewMode} />
          ))}
        </div>

        {/* Pagination Section */}
        <div className="pagination flex justify-center items-center mt-6 space-x-1">
          <button 
            onClick={() => paginate(currentPage - 1)} 
            className={`px-3 py-2 ${currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-green-500'}`}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {Array.from({ length: Math.ceil(filteredProducts.length / productsPerPage) }, (_, i) => (
            <button 
              key={i} 
              onClick={() => paginate(i + 1)} 
              className={`px-3 py-2 rounded ${currentPage === i + 1 ? 'bg-green-500 text-white' : 'bg-white text-green-500'}`}
            >
              {i + 1}
            </button>
          ))}
          <button 
            onClick={() => paginate(currentPage + 1)} 
            className={`px-3 py-2 ${currentPage === Math.ceil(filteredProducts.length / productsPerPage) ? 'text-gray-500 cursor-not-allowed' : 'text-green-500'}`}
            disabled={currentPage === Math.ceil(filteredProducts.length / productsPerPage)}
          >
            &gt;
          </button>
        </div>
      </div>
      
      {/* Final Message CTA Section */}
      <FinalMessageCTASection
        logo={Logo}
        mainHeading="បំបាត់មុនក្នុងយៈពេល 7 ថ្ងៃ"
        subHeading="សម្រស់ ប្រណិត ធម្មជាតិ"
        buttonText="ទិញ​ឥឡូវ"
        buttonLink="https://www.instagram.com/botum.official/?hl=en"
      />
    </div>
  );
}

export default Star;
