import React from 'react';
import Logo from '../images/logo.png';

const LogoComponent = () => {
  return (
    <div className="text-xl font-bold">
      <img src={Logo} className="w-48 sm:w-64 md:w-72 lg:w-80" alt="Botum Logo" />
    </div>
  );
};

export default LogoComponent;
