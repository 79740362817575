import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'; // Font Awesome Chevron Down icon
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate(); // useNavigate hook to programmatically navigate

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigation = (path) => {
    setIsDropdownOpen(false); // Close dropdown when a link is clicked
    navigate(path); // Navigate to the desired path
  };

  return (
    <nav>
      <ul className="flex space-x-8 text-lg md:text-xl font-semibold">
        <li>
          <a href="/" className="cursor-pointer hover:underline transition duration-300 ease-in-out hover:text-gray-300">
            Mask
          </a>
        </li>
        <li>
          <a href="/gua-sha" className="cursor-pointer hover:underline transition duration-300 ease-in-out hover:text-gray-300">
            Gua Sha
          </a>
        </li>
        
        <li className="relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center cursor-pointer hover:underline transition duration-300 ease-in-out hover:text-gray-300 focus:outline-none"
          >
            Game
            <FaChevronDown className={`ml-2 transform transition-transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`} />
          </button>

          {isDropdownOpen && (
            <ul className="absolute bg-white text-black shadow-lg  mt-2 rounded-md w-48 right-0 border border-gray-200 z-10 text-lg">
              <li>
                <button
                  onClick={() => handleNavigation('/game')}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-300 ease-in-out"
                >
                  Play Game
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation('/leaderboard')}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-300 ease-in-out"
                >
                  Leaderboard
                </button>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
