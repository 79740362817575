import React from 'react';

function FeatureCard({ title, description, icon }) {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <div className="flex items-center justify-center mb-4">
        {icon} {/* Render the icon component */}
      </div>
      <h3 className="text-xl text-center font-semibold mb-2">{title}</h3>
      <p className="text-gray-700 text-lg text-center">{description}</p>
    </div>
  );
}

export default FeatureCard;
