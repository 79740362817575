import React from 'react';

const PrizeList = () => {
  const prizes = [
    { rank: '1st Place', prize: 'លុយសុទ្ធ 50$' },

  ];

  return (
    <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:text-left p-6">
      <div className="space-y-4">
        <h1 className="text-4xl font-bold mb-4 text-primary">Prizes</h1>
        <ul className="space-y-2">
          {prizes.map((item, index) => (
            <li key={index} className="text-xl font-semibold text-gray-700">
              <span className="font-bold text-primary">{item.rank}:</span> {item.prize}
            </li>
          ))}
        </ul>
        <p className="text-lg font-bold mt-6 text-gray-500">លេងឥឡូវដើម្បីឈ្នះ! ប្រកាសរង្វាន់ថ្ងៃ​​ 30/09</p>
      </div>
    </div>
  );
};

export default PrizeList;
