import React, { useState } from 'react';
import FreeBotumBanner from './FreeBotumBanner';
import LogoComponent from './LogoComponent';
import NavBar from './NavBar';
import Modal from './Modal';

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='fixed w-full z-10'>
      {/* Free BOTUM Banner */}
      <FreeBotumBanner openModal={openModal} />

      <header className="bg-primary text-white border-black p-6 shadow-md">
        <div className="container mx-auto flex flex-col justify-center items-center space-y-4">
          {/* Logo */}
          <LogoComponent />

          {/* Navigation */}
          <NavBar />
        </div>
      </header>

      {/* Modal for Free BOTUM */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p className='text-lg md:text-xl'>
          ចែកគ្នាស្អាត ចំណេញទាំងអស់គ្នា❤️<br></br><br></br>
          FREE Botum ដោយណែនាំទៅកាន់មិត្តអ្នកដើម្បីទិញ Botum✨<br></br><br></br>
          សន្សំពិន្ទុតាមចំនួនមិត្តដែលទិញនិងប្រើឈ្មោះរបស់អ្នក:<br></br><br></br>
          មិត្តម្នាក់ = 1 ពិន្ទុ<br></br><br></br>
          មិត្ត2នាក់ = 2 ពិន្ទុ<br></br><br></br>
          ណែនាំច្រើន បានកាន់តែច្រើន😆
        </p>
      </Modal>
    </div>
  );
}

export default Header;
