import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Mask from './pages/Mask';
import GuaSha from './pages/GuaSha';
import Star from './pages/Star';
import Header from './components/Header';
import ProductDetail from './components/ProductDetail';
import Loyalty from './pages/Loyalty';
import LoadingScreen from './pages/LoadingScreen'; // Import the LoadingScreen component
import Game from './pages/Game'; 
import GameLeaderBoard from './pages/GameLeaderBoard';

function App() {
  return (
    <Router>
      <Header />
      <div className="min-h-screen bg-white text-black pt-16 sm:pt-20">
        <Routes>
          <Route path="/" element={<LoadingScreen />} /> {/* Set LoadingScreen as the default route */}
          <Route path="/game" element={<Game />} /> {/* Set LoadingScreen as the default route */}
          <Route path="/leaderboard" element={<GameLeaderBoard />} /> {/* Set LoadingScreen as the default route */}
          <Route path="/mask" element={<Mask />} />
          <Route path="/gua-sha" element={<GuaSha />} />
          <Route path="/loyalty" element={<Loyalty />} />
          <Route path="/star" element={<Star />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
