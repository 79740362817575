import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);

  // Fetch leaderboard data
  useEffect(() => {
    axios.get('https://dreamlo.com/lb/66a34be08f40bb11084afc39/json/10')
      .then(response => {
        const entries = response.data.dreamlo.leaderboard.entry;
        setLeaderboard(entries);
      })
      .catch(error => {
        console.error('Error fetching leaderboard:', error);
      });
  }, []);

  return (
    <div className="high-scores-section py-10 bg-white shadow-r-lg border-l md:rounded-tr-lg md:rounded-br-lg rounded-b-lg md:rounded-bl-none mx-auto w-full max-w-4xl">
      <h2 className="text-4xl font-bold text-gray-600 mb-8 text-center">High Scores</h2>
      <table className="w-full text-center">
        <thead>
          <tr className="bg-primary text-white">
            <th className="py-3">Rank</th>
            <th className="py-3">Name</th>
            <th className="py-3">Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.length > 0 ? leaderboard.map((entry, index) => (
            <tr key={index} className="border-t">
              <td className="py-3">{index + 1}</td>
              <td className="py-3">{entry.name}</td>
              <td className="py-3">{entry.score}</td>
            </tr>
          )) : (
            <tr>
              <td colSpan="3" className="py-3">Loading...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
