import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png'; // Adjust the path as needed

function LoadingScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    // Set a timer to navigate to Mask after 3 seconds
    const timer = setTimeout(() => {
      navigate('/mask'); // Redirect to the Mask component
    }, 3000); // Adjust the time (in milliseconds) as needed, e.g., 2000ms = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-primary">
      <style>
        {`
          @keyframes zoomIn {
            0% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(1);
            }
          }

          .animate-zoomIn {
            animation: zoomIn 3s ease-in-out;
          }
        `}
      </style>
      <img src={Logo} alt="Botum Logo" className="w-1/3 animate-zoomIn" />
    </div>
  );
}

export default LoadingScreen;
