import React, { useEffect, useState } from 'react';
import GuaShaImage from '../images/guaShaHero.png'; // Make sure you have an image for Gua Sha
import '../styles/HeroAnimations.css'; // Ensure the path is correct

function GuaShaHero() {
  const [showLimitedProduct, setShowLimitedProduct] = useState(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    const currentTime = new Date().getTime();

    if (!lastVisit || currentTime - lastVisit > 24 * 60 * 60 * 1000) {
      setShowLimitedProduct(true);
      localStorage.setItem('lastVisit', currentTime);
    }
  }, []);

  const handleCloseModal = () => {
    setShowLimitedProduct(false);
  };

  return (
    <div className="flex flex-wrap items-center h-screen bg-primary text-white  md:pt-0 md:justify-evenly">
      {/* Text Side */}
      <div className="w-full md:w-1/2 lg:px-20 xl:px-32 md:px-8 p-5 flex flex-col justify-center items-center text-center md:text-left animate-opacity">
        <div className="space-y-4 animate-fadeSlideUp md:mt-0 mt-32 sm:mt-12 px-2 md:px-0">
          <h1 className="md:text-3xl md:mt-16 lg:text-5xl font-bold mb-4 animate-textPop text-3xl nokora leading-normal">មុខតូច ស្អាតជាមួយ Gua Sha</h1>
          <p className="lg:text-3xl md:text-2xl text-lg text-gray-300">
            Gua Sha By BOTUM គឺជាឧបករណ៍ម៉ាស្សាមុខដែលធ្វើចេញពីដែក stainless steel ដែលមានតម្រង់ដែលធ្វើឲតម្រង់មុខតូច ស្អាតដោយធម្មជាតិ
          </p>
          <button className="nokora bg-white text-primary font-bold text-xl py-2 px-4 rounded hover:bg-gray-300 transition duration-300 ease-in-out shadow-md hover:shadow-lg animate-btnPulse">
          តាមដាន restock
          </button>
          <p className="text-lg font-bold nokora">ភ្ញៀវបានទិញ Gua Sha 500+ ឈុត!</p>
        </div>
      </div>

      {/* Image Side */}
      <div className="w-full md:w-1/2 xl:w-5/12 flex justify-center items-center p-5 animate-scaleIn md:mt-0">
        <img src={GuaShaImage} alt="Gua Sha Visual" className="xl:w-full w-2/3 md:w-3/4" />
      </div>

      {/* Limited Product Side */}
      {showLimitedProduct && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50 px-10"
          onClick={handleCloseModal}
        >
          <div
            className="bg-red-500 text-white p-6 rounded-lg shadow-lg relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 bg-white text-red-500 w-8 h-8 flex justify-center items-center rounded-full p-2 hover:bg-gray-300 transition duration-300 ease-in-out"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 className="md:text-3xl 2xl:text-4xl font-bold mb-4 text-2xl nokora">Out Of Stock</h2>
            <p className="lg:text-2xl md:text-xl text-lg">
              Gua Sha គឺត្រូវបានផលិតលើង 600 items ប៉ុណ្ណោះ! Gua Sha គឺត្រូវបានការគាំទ្រ និងល្បីនៅប្រទេសកម្ពុជាខ្លាំង
            </p>
            <button className="nokora bg-white text-primary font-bold text-xl py-2 px-4 rounded hover:bg-gray-300 transition duration-300 ease-in-out shadow-md hover:shadow-lg mt-4">
              តាមដាន restock
            </button>
            <p className="text-lg font-bold nokora mt-2">អរគុណដែលបានគាំទ្រ Gua Sha ឥឡូវ Gua Sha គឺអស់ស្តុកហើយ!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default GuaShaHero;
