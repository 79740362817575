import React, { useEffect } from 'react';
import FeatureCard from './FeatureCard';


function FeaturesSection({features}) {

  return (
    <div id="features" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4 flex items-center flex-col">
        <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-bold text-gray-600 mb-10 mt-4 relative inline-block">Features
            <span className="block w-full h-1 bg-primary mt-2"></span> {/* Underline Effect */}

        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              title={feature.title}
              description={feature.description}
              icon={feature.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
