import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const Game = () => {
  const hasTracked = useRef(false); // Use ref to track if the effect has been run

  useEffect(() => {
    if (!hasTracked.current) {
      hasTracked.current = true; // Mark as run after the first execution

      // Check for browser ID in localStorage
      let browserId = localStorage.getItem('browser_id');
      
      // If no browser ID exists, generate one
      if (!browserId) {
        browserId = generateUUID();
        localStorage.setItem('browser_id', browserId);
      }

      // Track the player
      axios.post('https://botum-6ea16f5d8e73.herokuapp.com/trackPlayer', { browserId })
        .then(response => {
          console.log('Player tracked:', response.data.message);
          // After tracking the player, redirect to WebGL
          window.location.href = 'https://botumofficial.com/webgl.html';
        })
        .catch(error => {
          console.error('Error tracking player:', error);
          // Redirect even if tracking fails
          window.location.href = 'https://botumofficial.com/webgl.html';
        });
    }
  }, []);

  // Function to generate a UUID
  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  return null; // No need to render anything as we're redirecting
};

export default Game;
