import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../images/logo.png';
import FinalMessageCTASection from "../components/FinalMessageCTASection";

const CheckCircle = ({ size = 24, color = "#66c44f" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="inline-block mr-2"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://botum-6ea16f5d8e73.herokuapp.com/guide/reviews/${productId}`)
      .then(response => response.json())
      .then(data => {
        setProduct(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [productId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="min-h-screen text-black">
      <div className="container mx-auto p-4 mt-20">
        <div className="flex flex-col md:flex-row mb-8 mt-20">
          <div className="md:w-1/3 mb-4 md:mb-0">
            <img src={product.image || 'path/to/placeholder-image.jpg'} alt={product.product_name} className="w-full h-auto object-cover rounded-md" />
          </div>
          <div className="md:w-2/3 md:pl-8">
            <h2 className="text-2xl font-semibold mb-2">{product.product_name}</h2>
            <p className="text-gray-700 mb-2"><strong>Skin Type:</strong> {product.skin_type}</p>
            <p className="text-gray-700 mb-2"><strong>Duration Of Use:</strong> {product.duration_of_use}</p>
            <p className="text-gray-700 mb-2"><strong>Price:</strong> ${product.price ? product.price.toFixed(2) : 'N/A'}</p>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Review Summary</h3>
          <div className="flex items-center mb-4">
            <p className="text-gray-700 mb-4">
              <CheckCircle size={16} color="#66c44f" />
              <strong>Rating: </strong>{product.rating}
              <span className="text-yellow-400">
                {'★'.repeat(Math.round(product.rating))}
                {'☆'.repeat(5 - Math.round(product.rating))}
              </span>
            </p>
          </div>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Approval from dermatologist:</strong> {product.dermatologist_approval}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Ingredients:</strong> {product.ingredients}
          </p>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">User Experience</h3>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Texture:</strong> {product.texture}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Scent:</strong> {product.scent}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Absorption:</strong> {product.absorption}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Usage:</strong> {product.usage_method}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Immediate Effect:</strong> {product.immediate_effect}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Long Term Effect:</strong> {product.long_term_effect}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Pros:</strong> {product.pros}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Cons:</strong> {product.cons}
          </p>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Packaging & Social Responsibility</h3>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Packaging:</strong> {product.packaging}
          </p>
          <p className="text-gray-700 mb-4">
            <CheckCircle size={16} color="#66c44f" />
            <strong>Cruelty Free:</strong> {product.cruelty_free}
          </p>
        </div>
      </div>

      <FinalMessageCTASection
        logo={Logo}
        mainHeading="បំបាត់មុនក្នុងយៈពេល 7 ថ្ងៃ"
        subHeading="សម្រស់ ប្រណិត ធម្មជាតិ"
        buttonText="ទិញ​ឥឡូវ"
        buttonLink="https://www.instagram.com/botum.official/?hl=en"
      />
    </div>
  );
};

export default ProductDetail;
