import React, { useState } from 'react';
import axios from 'axios';

function Loyalty() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loyaltyData, setLoyaltyData] = useState(null);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.post('https://botum-6ea16f5d8e73.herokuapp.com/loyaltyPoints/getMember', {
        phoneNumber,
      });
      setLoyaltyData(response.data[0]);
      setError('');
    } catch (error) {
      setError('Error fetching member data. Please try again.');
      setLoyaltyData(null);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-primary text-white">
      <div className="bg-white text-primary p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-6 nokora">Search Customer Loyalty Points</h1>
        <div className="mb-4">
          <label className="block text-lg font-bold mb-2 nokora" htmlFor="phoneNumber">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-3 py-2 text-primary border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            placeholder="Enter phone number"
          />
        </div>
        <button
          onClick={handleSearch}
          className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600 transition duration-300 ease-in-out"
        >
          Search
        </button>
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>

      {loyaltyData && (
        <div className="mt-8 bg-white text-primary p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-xl font-bold text-center mb-4 nokora">Loyalty Points Details</h2>
          <p className="mb-2"><span className="font-bold nokora">Phone Number:</span> {loyaltyData.phoneNumber}</p>
          <p className="mb-2"><span className="font-bold nokora">Points:</span> {loyaltyData.Points}</p>
          <p className="mb-2"><span className="font-bold nokora">Last Updated:</span> {new Date(loyaltyData.LastUpdated).toLocaleDateString()}</p>
        </div>
      )}
    </div>
  );
}

export default Loyalty;
