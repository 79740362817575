import React from 'react';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  // Handle click outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      onClose();
    }
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOutsideClick}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/2"
        onClick={(e) => e.stopPropagation()} // Prevent click inside the modal content from closing it
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold md:text-3xl">ហៅមិត្ត​​ FREE BOTUM!</h2>
          <button
            onClick={onClose}
            className="text-gray-700 hover:text-gray-900 transition duration-300"
          >
            &times;
          </button>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
