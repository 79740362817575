import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../styles/CustomSwiperStyles.css'; // Assuming this file contains your custom Swiper styles

import before1 from '../images/testimonials/before1.png';
import before2 from '../images/testimonials/before2.png';
import before3 from '../images/testimonials/before3.jpg';
import before4 from '../images/testimonials/before4.png';
import before5 from '../images/testimonials/before5.png';
import before6 from '../images/testimonials/before6.jpg';
import before7 from '../images/testimonials/before7.png';

import after1 from '../images/testimonials/after1.png';
import after2 from '../images/testimonials/after2.png';
import after3 from '../images/testimonials/after3.jpg';
import after4 from '../images/testimonials/after4.png';
import after5 from '../images/testimonials/after5.png';
import after6 from '../images/testimonials/after6.jpg';
import after7 from '../images/testimonials/after7.png';


const StarRating = ({ rating }) => {
  return (
    <div className="flex justify-center mt-4">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <span key={index} className={index <= rating ? "text-yellow-500" : "text-gray-300"}>
            ★
          </span>
        );
      })}
    </div>
  );
};

const TestimonialSection = () => {
  const testimonials = [
    {
      beforeImage: before5,
      afterImage: after5,
      feedback: "trust the process mg bong 🥰🥰",
      rating: 5,
      name: "Ry Lita",
    },

    {
      beforeImage: before2,
      afterImage: after2,
      rating: 5,
      feedback: "ជួយដល់មុនខ្ទុះខ្លាំង",
      name: "Chamroeun",
    },

      {
        beforeImage: before7,
        afterImage: after7,
        feedback: "B kron jeang mun far 💕",
        rating: 5,
        name: "Na",
      },
      {
        beforeImage: before6,
        afterImage: after6,
        feedback: "មុខញុមគ្រាន់បើជាងមុនហើយបង 🤍",
        rating: 5,
        name: "Tra KalitTo",
      },
      {
        beforeImage: before4,
        afterImage: after4,
        feedback: "Terb use os 1set te. nh lerng jg os sngkherm ng mok klun eg hz mk ban use butom lerng mok jea sby jit mg oy feedback tang yub",
        rating: 5,
        name: "nayok_1234",
      },
      {
        beforeImage: before1,
        afterImage: after1,
        feedback: "វាត្រជាក់អត់ធ្ងន់មុខ ទេ. ហើយរន្ធរោមតូចជាងមុន។ មុនប្រើមួយថ្ងៃ រន្ធរោមធំ។ ល្អប្រើមែនតើ. ជាងម៉ាសគេធម្មជាតិ",
        rating: 5,
        name: "Lii Lii Sa Sa",
      },

      {
        beforeImage: before3,
        afterImage: after3,

        feedback: "Jrg oy feedback b klas ❤️",
        rating: 5,
        name: "ម៉ៃ",
      },
    // Add more testimonials as needed
  ];
  return (
    <div id="testimonials" className="py-10 px-4 md:px-10 bg-gray-50 flex justify-center">
      <div className="max-w-4xl w-full">
        <div className="w-full flex items-center justify-center">
          <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-bold text-gray-600 mb-10">
            Feedback
            <span className="block w-full h-1 bg-primary mt-2"></span>
          </h2>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
          }}
          navigation
          pagination={{ clickable: true }}
          className="custom-swiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className="flex items-center justify-center">
              <div className="flex flex-col items-center justify-center mx-auto w-full max-w-lg p-6 bg-white rounded-lg shadow-lg ">
                {testimonial.beforeImage && testimonial.afterImage ? (
                  <div className="grid grid-cols-2 gap-4 w-full mb-4">
                    <div className="relative flex items-center">
                      <img src={testimonial.beforeImage} alt="Before" className="w-full object-cover rounded-md" />
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-sm font-bold py-1 text-center">Before</div>
                    </div>
                    <div className="relative flex items-center">
                      <img src={testimonial.afterImage} alt="After" className="w-full object-cover rounded-md" />
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-sm font-bold py-1 text-center">After</div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <img src={testimonial.image} alt="Before" className="w-full rounded-md" />
                  </div>
                )}
                <p className="text-gray-600 italic mb-4">"{testimonial.feedback}"</p>
                <StarRating rating={testimonial.rating} />
                <p className="font-semibold text-lg text-primary mt-2">{testimonial.name}</p>
                <p className="text-sm text-secondary">{testimonial.position}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default TestimonialSection;
