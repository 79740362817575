import React from 'react';
import FeaturesSection from "../components/FeaturesSection";
import FinalMessageCTASection from "../components/FinalMessageCTASection";
import HeroSection from "../components/HeroSection";
import InfluencerShowcase from "../components/InfluencerShowcase";
import TestimonialSection from "../components/TestimonialSection";
import LuxuriousPopup from "../components/LuxuriousPopup"; // Importing the Popup
import { FaLeaf, FaEye } from 'react-icons/fa'; // Importing necessary icons
import { AiFillSafetyCertificate } from "react-icons/ai";
import Logo from '../images/logo.png';

function Mask() {
  const features = [
    {
      title: 'ធម្មជាតិ 100%',
      description: 'ផលិតផលរបស់យើងត្រូវបានផលិតចេញពីគ្រឿងផ្សំធម្មជាតិទាំងស្រុង ធានាបាននូវភាពបរិសុទ្ធ និងសុវត្ថិភាពសម្រាប់ស្បែករបស់អ្នក។ ទទួលបានបទពិសោធន៍ និងអត្ថប្រយោជន៍នៃធម្មជាតិក្នុងគ្រប់បញ្ហាស្បែក។',
      icon: <FaLeaf size={30} color='#32A605'/>, // Assigning FaLeaf icon to the first feature
    },
    {
      title: 'ប្រើភ្លាម បានផលភ្លាម',
      description: 'ឃើញលទ្ធផលតាំងពីប្រើលើកដំបូង។ រូបមន្តរបស់យើងត្រូវបានផ្សំឡើងដើម្បីផ្តល់នូវប្រសិទ្ធភាពភ្លាមៗ និងគួរឱ្យកត់សម្គាល់ ផ្តល់ឱ្យអ្នកនូវទំនុកចិត្តដែលអ្នកសមនឹងទទួលបាន។',
      icon: <FaEye size={30} color='#F77A7F'/>, // Assigning FaEye icon to the second feature
    },
    {
      title: 'សុវត្ថិភាព និងទទួលស្គាល់ពី FDA Thailand',
      description: 'ផលិតផលរបស់យើងត្រូវបានបញ្ជាក់ដោយ FDA នៅក្នុងប្រទេសថៃ ដោយធានាថាវាបំពេញតាមស្តង់ដារសុវត្ថិភាព និងគុណភាពខ្ពស់បំផុត។ ជឿជាក់លើផលិតផលដែលមានប្រសិទ្ធភាព និងសុវត្ថិភាព។',
      icon: <AiFillSafetyCertificate size={30}  color='rgb(234 179 8)'/>, // Assigning FaCertificate icon to the third feature
    },
  ];

  return (
    <div className="min-h-screen text-black">
      {/* Hero Section */}
        <HeroSection/>
        <TestimonialSection/>
        <InfluencerShowcase/>
        <FeaturesSection features= {features}/>
        <FinalMessageCTASection
          logo={Logo}
          mainHeading="បំបាត់មុនក្នុងយៈពេល 7 ថ្ងៃ"
          subHeading="សម្រស់ ប្រណិត ធម្មជាតិ"
          buttonText="ទិញ​ឥឡូវ"
          buttonLink="https://www.instagram.com/botum.official/?hl=en"
        />
        <LuxuriousPopup /> {/* Add the Popup component here */}
    </div>
  );
}

export default Mask;
