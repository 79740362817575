import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ product, viewMode }) {
  const [isHovered, setIsHovered] = useState(false);
  const placeholderImage = 'path/to/placeholder-image.jpg'; // Replace with an actual placeholder image path

  return (
    <div
      className={`product-card p-4 ${viewMode === 'list' ? 'flex flex-row' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={`/product/${product.id}`} className={`${viewMode === 'list' ? 'w-1/3' : ''}`}>
        <img
          src={product.image || placeholderImage}
          alt={product.product_name}
          className={`w-full h-64 object-contain rounded-md ${viewMode === 'list' ? 'h-auto' : ''}`}
          style={{ minHeight: '200px', maxHeight: '300px' }}
        />
      </Link>
      <div className={`product-details mt-4 ${viewMode === 'list' ? 'w-2/3 pl-4 mt-0' : 'text-center'}`}>
        <div className="flex justify-between items-center">
          <div className="text-left">
            <h3 className="text-lg font-semibold">{product.product_name}</h3>
            <p className="text-gray-800 font-bold">${product.price ? product.price.toFixed(2) : 'N/A'}</p>
          </div>
          <div className="text-right">
            <div className="flex items-center justify-end">
              <span className="text-yellow-400">
                {'★'.repeat(Math.round(product.stars))}
                {'☆'.repeat(5 - Math.round(product.stars))}
              </span>
              <span className="text-gray-500 ml-2">({product.stars.toFixed(1)})</span>
            </div>
            <p className="text-gray-600">{product.capacity || ''}</p>
          </div>
        </div>
        {isHovered && (
          <div className="flex justify-between items-center mt-2">
            <button className="p-2 border rounded-md mr-2 bg-green-500">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 016.364 0L12 7.636l1.318-1.318a4.5 4.5 0 016.364 6.364l-7.682 7.682a.5.5 0 01-.707 0l-7.682-7.682a4.5 4.5 0 010-6.364z"
                />
              </svg>
            </button>
            <Link to={`/product/${product.id}`} className="flex-grow">
              <button className="w-full px-4 py-2 bg-green-500 text-white rounded-md">View Details</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
